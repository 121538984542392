import React from 'react';
import config from '../../config';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className='row bg-secondary mb-3 p-4'>
          <div className='container text-white'>
            <div className='row'>
              <div className='col-12 col-lg-3 mb-3'>
                <h4 className='h4 logo__footer text-primary'></h4>
                <p className='text-left'>En Beköme Digital queremos ser tu socio tecnológico para que tu negocio tenga éxito.</p>
                <img className='responsive mr-3' src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,w_100,e_replace_color:white/v1652620560/bekome/adobe-commerce_ruugdd.png" alt="Adobe Commerce" />
                <img className='responsive' src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto,w_100/v1652610539/bekome/Partner-Certified-Wordmark-White_dhzvbz.png" alt="BigCommerce" />
              </div>
              <div className='col-12 col-md-4 col-lg-3'>
                <h4 className='h4'>Servicios</h4>
                <ul className='list-unstyled footer-list'>
                  <li>
                    <a href="/servicios/#estrategia">Consultoría Ecommerce</a>
                  </li>
                  <li>
                    <a href="/servicios/#desarrollo">Desarrollo Ecommerce</a>
                  </li>
                  <li>
                    <a href="/servicios/#soporte">Soporte y mantenimiento</a>
                  </li>
                </ul>
              </div>
              <div className='col-12 col-md-4 col-lg-3'>
                <h4 className='h4'>Soluciones</h4>
                <ul className='list-unstyled footer-list'>
                  <li>
                    <Link to="/soluciones-ecommerce-b2b/">Proyectos Ecommerce B2B</Link>
                  </li>
                  <li>
                    <Link to="/soluciones-ecommerce-b2c/">Proyectos Ecommerce B2C</Link>
                  </li>
                  <li>
                    <Link to="/migracion-magento-2/">Migración a Magento 2</Link>
                  </li>
                </ul>
              </div>
              <div className='col-12 col-md-4 col-lg-3'>
                <h4 className='h4'>Contacto</h4>
                <p>{config.street}<br />{config.postcode}<br />{config.region}</p>
                <p>{config.phone}<br />{config.email}</p>
                <a className="social-icon" href="https://www.linkedin.com/company/bekome-digital" target="_blank" title="Experto ecommerce"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>

            <p className="text-muted small mb-1">
              &copy;{config.copy_footer}
            </p>
            <p className="text-muted mb-0">
              <Link className="mr-3" to="/politica-privacidad/">Privacidad</Link>
              <Link className="mr-3" to="/politica-cookies/">Cookies</Link>
              <Link to="/">Empleo</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
