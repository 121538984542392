import React from 'react';
import LayoutEs from '../components/LayoutEs';
import Header from '../components/Header';
import SEO from '../components/Seo';
import Footer from '../components/Footer';

const PoliticaPrivacidad = () => (
    <LayoutEs>
        <Header />
        <SEO title="Política de privacidad"
            description="Política de privacidad"
            robots="NOINDEX,NOFOLLOW" />
        <header className="masthead privacy">
            <div className="text-center">
            <h1 className="display-3">Política de privacidad</h1>
                <h4 className="lead mt-5">
                    privacidad ante todo...
                </h4>
            </div>
            <div className="overlay"></div>
        </header>
        <div className="container mt-5 mb-5">
            <h4>Beköme en cumplimiento de lo establecido en el Reglamento General de Protección de datos (RGPD 2016/679), informa a los usuarios de la página web www.bekome.digital:</h4>
            <p>
                La presente Política de Privacidad establece los términos en que Beköme usa y protege la información que es proporcionada por sus usuarios al momento de utilizar
                su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la
                cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de
                Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de
                acuerdo con dichos cambios.
            </p>
            <p>
                <strong>Información que es recogida</strong><br />
        Nuestro sitio web podrá recoger información personal por ejemplo: Nombre,  información de contacto como  su dirección de correo electrónica e información
        demográfica. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.
        </p>
            <p>
                <strong>Uso de la información recogida</strong><br />
        Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en
        caso que aplique, y mejorar nuestros productos y servicios.  Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas
        especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos
        electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.<br />
        Beköme está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos
        constantemente para asegurarnos que no exista ningún acceso no autorizado.
        </p>
            <p>
                <strong>Cookies</strong><br />
            Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la
            cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las
            cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.
        Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para
        análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador.
        Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de
        que usted así lo quiera y la proporcione directamente, . Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies
        automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies.
        Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.
        </p>
            <p>
                <strong>Enlaces a Terceros</strong><br />
            En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.
            Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico.
             caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.<br />
             Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez
             con un orden judicial.<br />
             Beköme Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
        </p>
        </div>
        <Footer />

    </LayoutEs>
);

export default PoliticaPrivacidad;